import {service} from '../index'

// 导航栏
export function postColumn() {
	return service.post({
		url: '/index/getColumn'
	})
}

// 配置信息
export function postConfig() {
	return service.post({
		url: '/index/config'
	})
}

// 首页数据
export function postHome() {
	return service.post({
		url: '/index/index'
	})
}

// 标题列表
export function postTitleList(data) {
	return service.post({
		url: '/index/titleList',
		data
	})
}

// 内容列表
export function postColumnList(data) {
	return service.post({
		url: '/index/columnList',
		data
	})
}

export function postSearchFor(data) {
	return service.post({
		url: '/index/searchFor',
		data
	})
}

// 意见反馈
export function postFeedbackDo(data) {
	return service.post({
		url: '/index/feedbackDo',
		data
	})
}

// 服务列表
export function postServeList(data) {
	return service.post({
		url: '/index/serveList',
		data
	})
}

// 服务内容数据
export function postServeDetail(data) {
	return service.post({
		url: '/index/serveDetail',
		data
	})
}

// 人员信息标题列表
export function postUserTitleList(data) {
	return service.post({
		url: '/index/userTitleList',
		data
	})
}

// 人员（教练或运动员）列表
export function postUserList(data) {
	return service.post({
		url: '/index/userList',
		data
	})
}

// 精彩时刻视频接口
export function postVideoList(data) {
	return service.post({
		url: '/index/video',
		data
	})
}

// 视频详情
export function postVideoDetail(data) {
	return service.post({
		url: '/index/videoDetail',
		data
	})
}

// 相关视频
export function postVideoRecommend() {
	return service.post({
		url: '/index/videoRecommend'
	})
}

// 精彩时刻图片接口
export function postAlbum(data) {
	return service.post({
		url: '/index/images',
		data
	})
}

// 图片详情
export function postAlbumDetail(data) {
	return service.post({
		url: '/index/albumDetail',
		data
	})
}

// 相关图片
export function postAlbumRecommend() {
	return service.post({
		url: '/index/albumRecommend'
	})
}

// 详情数据
export function postDetail(data) {
	return service.post({
		url: '/index/detail',
		data
	})
}

export function postTitle(data) {
	return service.post({
		url: '/index/getTitle',
		data
	})
}

// banner
export function getBanner(data) {
	return service.post({
		url: '/index/getBanner',
		data
	})
}

// 培训视频接口
export function trainVideo(data) {
	return service.post({
		url: '/index/trainVideo',
		data
	})
}

// 赛事列表
export function getActiveList(data) {
	return service.post({
		url: '/index/activeList',
		data
	})
}

// 赛事列表
export function getTrainList(data) {
	return service.post({
		url: '/index/trainList',
		data
	})
}

// 赛事列表分类
export function getClassifyList(data) {
	return service.post({
		url: '/index/classify',
		data
	})
}

// 赛事详情
export function getActiveInfo(data) {
	return service.post({
		url: '/index/activeInfo',
		data
	})
}

// 培训详情
export function getTrainInfo(data) {
	return service.post({
		url: '/index/trainInfo',
		data
	})
}

// 面包屑
export function getBreadCrumb(data) {
	return service.post({
		url: '/index/getBreadCrumb',
		data
	})
}

// 场地列表
export function getSiteList(data) {
	return service.post({
		url: '/index/siteList',
		data
	})
}

// 场地详情
export function getSiteInfo(data) {
	return service.post({
		url: '/index/siteInfo',
		data
	})
}
