<template>
	<div class="footer">
		<div class="footer-top">
			<div class="content flex-space-between">
				<div class="footer-top-item flex">
					<div class="footer-top-left flex-direction-column">
						<img :src="$store.state.config.logo"/>
						<div class="link flex">
							友情链接：
							<div class="flex" v-for="(item, index) in info.friendship" :key="index">
								<a :href="item.url" target="_blank" class="one-line">{{ item.name }}</a>
								<p v-if="index != info.friendship.length - 1">I</p>
							</div>
						</div>
					</div>
				</div>
				<div class="line_shu"></div>
				<div class="footer-top-item flex" style="padding-left: 20px">
					<div class="footer-top-center flex-direction-column">
						<div class="footer-top-center-title">联系我们</div>
						<div class="phone flex">{{ info.phone }}</div>
						<span>联系人：{{ info.contacts }}</span>
						<span>邮编：{{ info.postal_code }}</span>
						<span>邮箱：{{ info.mailbox }}</span>
						<span>地址：{{ info.address }}</span>
					</div>
				</div>
				<div class="line_shu"></div>
				<div class="footer-top-right flex">
					<div class="footer-top-right-item flex-direction-column" v-for="(item, index) in info.qr_code" :key="index">
						<img :src="item.src"/>
						<span>{{ item.name }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="content flex-direction-column flex">
				<span class="flex">{{ info.copyright }}
					<span v-if="info.jszc">&emsp;|&emsp;{{ info.jszc }}</span>
					<span @click="openLink('https://beian.miit.gov.cn')" v-if="info.icp_xkz">&emsp;|&emsp;{{
							info.icp_xkz
						}}</span>
					&emsp;|&emsp;
					<span v-if="info.police_record_no">{{ info.police_record_no }}</span>
				</span>
				<span class="flex">
					{{ info.st_xkz_no }}
					<span v-if="info.fzjg">&emsp;|&emsp;{{ info.fzjg }}</span>
					<span v-if="info.kb_phone">&emsp;|&emsp;{{ info.kb_phone }}</span>
					<span v-if="info.kb_mailbox">&emsp;|&emsp;{{ info.kb_mailbox }}</span>
				</span>

			</div>
		</div>
	</div>
</template>

<script>
import {postConfig} from '@/service/api/index'

export default {
	data() {
		return {
			info: {}
		}
	},

	mounted() {
		this.postConfig()
	},

	methods: {
		postConfig() {
			postConfig().then((res) => {
				this.info = Object.freeze(res.msg)
				this.$store.state.config = res.msg
				// console.log(this.info)
			})
		},
		openLink(url) {
			window.open(url, '_blank')
		}
	}
}
</script>

<style lang="less" scoped>
.footer {
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	.footer-top {
		background: #fff;
		padding: 20px 0;

		.footer-top-item {
			//&::after {
			//	content: ' ';
			//	height: 48px;
			//	width: 1px;
			//	background: #dddddd;
			//	margin: 0 30px;
			//}
		}

		.line_shu {
			height: 48px;
			width: 1px;
			background: #dddddd;
		}

		.footer-top-left {
			img {
				width: 408px;
				height: 104px;
			}

			.link {
				width: 380px;
				margin-top: 25px;
				color: #666666;
				font-size: 12px;
				margin-left: 20px;
				align-items: center;

				a {
					// width: 40px;
					text-decoration: none;
					color: #666666;
				}

				p {
					margin: 0 5px;
					color: #dddddd;
				}
			}
		}

		.footer-top-center {
			justify-content: space-between;

			.footer-top-center-title {
				height: 20px;
				font-size: 18px;
				// font-weight: 800;
				line-height: 20px;
				color: #263035;
			}

			.phone {
				height: 30px;
				font-size: 18px;
				font-weight: bold;
				line-height: 30px;
				color: #555555;
				align-items: flex-end;
				margin-top: 10px;

				&::before {
					content: '电话：';
					color: #777777;
					font-size: 14px;
					font-weight: normal;
					display: block;
				}
			}

			span {
				width: 380px;
				height: 22px;
				font-size: 14px;
				line-height: 22px;
				color: #777777;
				margin-top: 8px;
			}
		}

		.footer-top-right {
			.footer-top-right-item {
				justify-content: center;
				align-items: center;
				//margin-right: 15px;
				width: 170px;

				&:nth-child(3n) {
					margin-right: 0;
				}

				img {
					width: 110px;
					height: 110px;
				}

				span {
					height: 22px;
					font-size: 12px;
					line-height: 22px;
					color: #777777;
					margin-top: 5px;
				}
			}
		}
	}

	.footer-bottom {
		//border-top: 6px solid #e50114;
		width: 100%;
		padding: 15px 0;
		background: rgba(51, 51, 51);
		font-size: 12px;
		cursor: pointer;

		span {
			line-height: 22px;
			color: #ffffff;
			height: 22px;
		}

		p {
			color: #ffffff;
			margin: 0 20px;
		}

		img {
			width: 18px;
			height: 20px;
			margin-right: 10px;
		}
	}
}
</style>
