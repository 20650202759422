import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {

		// sessionStorage.setItem('navbarIndex', this.currentIndex)
		// sessionStorage.setItem('navbarId', item.id)
		// sessionStorage.setItem('currentIDChoose', item.id)
		// 作为侧边栏的选中id
		listId: sessionStorage.getItem('listId') ?? 0,
		//公共配置
		config: '',
		//刷新页面存储的传参数据
		params: sessionStorage.getItem('params') ?? "{}",
	},
	mutations: {},
	actions: {},
	modules: {}
})
