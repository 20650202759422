import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {globalRegister} from './global'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {showLoading, hideLoading} from '@/utils/loading';

Vue.component(showLoading);
Vue.component(hideLoading);

Vue.use(ElementUI);
// 显示右上角螺旋加载提示
NProgress.configure({showSpinner: false})
Vue.config.productionTip = false
Vue.use(globalRegister)

router.beforeEach((to, from, next) => {
	if (to.matched.length == 0) {
		return next('/')
	}
	// 开启进度条
	NProgress.start()
	next()
})

router.afterEach(() => {
	// 关闭进度条
	NProgress.done()
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app')
